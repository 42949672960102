export interface IMdsSelectOptions<T> {
  value: T;
  label: string;
}

export const MdsConstants = {
  'ItemsPerPageDDl': [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '75', label: '75' },
    { value: '100', label: '100' },
    { value: '150', label: '150' },
  ],
  'PaginatorVisiblePages': 5,
};

export const ItemsPerPageDDl: IMdsSelectOptions<string>[] = [
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '75', label: '75' },
  { value: '100', label: '100' },
  { value: '150', label: '150' },
];

export const RiskSeverityOptions: IMdsSelectOptions<string>[] = [
  { value: '1', label: 'Low' },
  { value: '2', label: 'Medium' },
  { value: '3', label: 'High' },
];

export const RiskStatusOptions: IMdsSelectOptions<string>[] = [
  { value: 'Open', label: 'Open' },
  { value: 'InProgress', label: 'Ongoing' },
  { value: 'Fixed', label: 'Resolved' },
];

export const booleanAnswer: IMdsSelectOptions<string>[] = [
  { value: '0', label: 'No' },
  { value: '1', label: 'Yes' },
];

export const ratingAnswer: IMdsSelectOptions<string>[] = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' }
];

export const enum appRoles {
  'Inspector' = 'Surveyor',
  'Admin' = 'Admin',
  'Vessel' = 'Vessel',
}

export type TImage = {
  url: string;
  alt: string;
  isExisting: boolean;
  fileName: string;
  imageId: number;
  file: File;
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const enum StringConstants {
  QuestionAddError = 'Cannot add questions to a Parent Section',
  QuestionAddErrorDetails = 'Please tap on the sub-sections to add questions or delete all the sub-sections.',
  SectionAddError = 'The selected section already has questions. Please remove the questions before adding a sub-section.',
  QuestionResponseAddDefectTitleText = 'The responses for the listed questions are not matching with the expected values.',
  QuestionResponseAddDefectActionText = 'If you want to keep these responses, please add at least one risk for the respective question(s).',
  QuestionResponseAddCommentTitleText = 'The responses for the listed questions are marked as Not Inspected or Not Applicable.',
  QuestionResponseAddCommnentActionText = 'Please add comments for the respective question(s).',
  ImageInputError = 'Error uploading the selected images. Please try again with fewer images or reload the page.',
  OverdueRiskTooltip = 'Open risks with due date in the past',
  RiskDue7DaysTooltip = 'Open risks that are due within the next 7 days',
  RiskDue30DaysTooltip = 'Open risks that are due within the next 30 days',
  RiskPendingVerificationTooltip = 'Risks resolved by vessel and pending verification by shore',
  RiskPostponementApprovalTooltip = 'Risks pending approval for postponement',
  PlaceholderText = 'Type to search',
}

export const enum SurveyStatusEnum {
  SURVEY_PLANNED = 'Survey Planned',
  SURVEY_STARTED = 'Survey Started',
  SURVEY_CLOSED = 'Survey Closed',
  SURVEY_CANCELLED = 'Survey Cancelled',
}

export const enum SurveyStatusEnumIds {
  SURVEY_PLANNED = 2,
  SURVEY_STARTED = 3,
  SURVEY_CLOSED = 4,
  SURVEY_CANCELLED = 5,
}

export const enum QuestionResponseTypes {
  INFO_TYPE = 'Information',
  BOOLEAN_TYPE = 'Boolean',
  RATING_TYPE = 'Rating',
  TEXT_TYPE = 'Text',
  YES_NO_TYPE = 'Yes/No',
}

export const enum RiskStatusEnum {
  RESOLVED = 'Fixed',
  OPEN = 'Open',
  ONGOING = 'InProgress',
  CLOSED = 'Verified',
}

export const enum RiskCounterEnumIds {
  OVERDUE = 1,
  DUE_IN_7_DAYS = 2,
  DUE_IN_30_DAYS = 3,
  PENDING_VERIFICATION = 4,
  PENDING_POSTPONEMENT_APPROVAL = 5,
}

export class LocationModel {
  id?: number;
  parentId?: number;
  name?: string;
  isActive?: string;
  constructor() { 
    this.id = 0;
    this.parentId = 0;
    this.name = '';
    this.isActive= 'False';
  }
}
type TGenericMapper<T> = {
  [key: number]: T;
 }
export const SectionTypesMap: TGenericMapper<string> = {
  1: 'Executive Summary',
  2: 'Opening Meeting Notes',
  3: 'Personnel Interviewed',
  4: 'Closing Meeting Notes',
  5: 'Care for Seafarers',
  6: 'Best Practices',
}

export const ImageCompressionConfig = {
  maxSizeMB: 0.45,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export const enum ImageUploadLimits {
  MAX_IMAGE_SIZE_LIMIT = 290 * 1024 * 1024, // Approx 300MB in bytes
  MAX_IMAGE_NUMBER_LIMIT = 100,
}

export enum ValidationErrors {
  EXCEEDS_NUMBER_LIMIT= 'EXCEEDS_NUMBER_LIMIT',
  EXCEEDS_SIZE_LIMIT= 'EXCEEDS_SIZE_LIMIT'
}

export interface IApiResponse {
  success: boolean;
  message: string;
  statusText?: string;
  statusCode: number;
  data?: any; //TODO: Will use this recieve the response body in ajax calls
}

// Local storage key for storing the last feedback timestamp
export const LAST_FEEDBACK_TIMESTAMP_KEY = 'last_feedback_timestamp'; 

// Local storage key for storing the first login timestamp
export const FIRST_LOGIN_TIMESTAMP_KEY = 'first_login_timestamp';

export const IT_HELP_URL = 'https://maersk.service-now.com/end_user_portal?id=sc_cat_item&sys_id=7f2fb8e2fbea8a90c360fe57aeefdc0d';