import { post, get } from '../utils/ajax';
import { SurveyListAllRequestModel } from '../pages/Surveys/models/models';
import env from 'react-dotenv';
import { SurveyDetailsUpdateModel, SurveyQuestionPayloadModel, SurveySectionResponseModel } from '../pages/PerformInspection/model';
const { REACT_APP_API_URL: BASE_URL } = env;
let abortController: AbortController;

export const getSuverys = async (body: SurveyListAllRequestModel) => {
  // Abort all previous requests..
  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();
  return get(`${BASE_URL}SurveyRequest/GetSurveyRequestList`).catch((err) => {
    // Do not throw errors when request has been aborted
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};

export const getSurveyCompanies = async () => {
  // Abort all previous requests..
  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();
  return get(`${BASE_URL}Survey/GetSurveyCompanies`).catch((err) => {
    // Do not throw errors when request has been aborted
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};

export const getSurveyTypes = async () => {
  // Abort all previous requests..
  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();
  return get(`${BASE_URL}SurveyRequest/GetSurveyTypes`).catch((err) => {
    // Do not throw errors when request has been aborted
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};

export const getSurveyTypesData = async (surveyId: number) => {
  // Abort all previous requests..
  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();
  return get(`${BASE_URL}SurveyRequest/GetSurveyRequestById?surveyRequestId=${surveyId}`).catch(
    (err) => {
      // Do not throw errors when request has been aborted
      if (!(err instanceof DOMException)) {
        throw Error(err);
      }
    },
  );
};

export const getSurveyTypeData = async (surveyTypeId: number) => {
  const requestUrl =
    surveyTypeId === 0
      ? `${BASE_URL}SurveyRequest/GetSurveyTypeData`
      : `${BASE_URL}SurveyRequest/GetSurveyTypeData?surveyTypeId=${surveyTypeId}`;
  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();
  return get(requestUrl).catch((err) => {
    // Do not throw errors when request has been aborted
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};

export const getSurveySectionData = async () => {
  // Abort all previous requests..
  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();
  return get(`${BASE_URL}SurveyRequest/GetSurveySections`).catch((err) => {
    // Do not throw errors when request has been aborted
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};

export const getSurveyInfoById = async (surveyId: number) => {
  // Abort all previous requests..
  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();
  return get(`${BASE_URL}SurveyRequest/GetSurveyRequestById?surveyRequestId=${surveyId}`).catch(
    (err) => {
      // Do not throw errors when request has been aborted
      if (!(err instanceof DOMException)) {
        throw Error(err);
      }
    },
  );
};

export const getSurveyor = async () => {
  // Abort all previous requests..
  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();
  return get(`${BASE_URL}user/GetSurveyors`).catch((err) => {
    // Do not throw errors when request has been aborted
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};

export const getFleetGroups = async () => {
  // Abort all previous requests..
  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();
  return get(`${BASE_URL}user/GetFleetGroups`).catch((err) => {
    // Do not throw errors when request has been aborted
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};

export const getSuperintendents = async () => {
  // Abort all previous requests..
  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();
  return get(`${BASE_URL}user/GetSuperintendents`).catch((err) => {
    // Do not throw errors when request has been aborted
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};

export const getSurveyNotesSectionsById = async (surveyId: number) => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  return get(`${BASE_URL}SurveyAnswer/GetSurveySectionsAns/${surveyId}`).catch((err) => {
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};

export const getAttendeesRanks = async () => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  return get(`${BASE_URL}SurveyAnswer/GetAttendeeRank`).catch((err) => {
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};

export const GetSurveySectionLocations = async () => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  return await get(`${BASE_URL}RunningWorklist/GeDefectLocationList`).catch((err) => {
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};

export const getSurveyQuestionnaireDetails = async (
  surveyId: number,
  questionnaieId: number = 0,
  sectionId: number = 0,
) => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  return get(
    `${BASE_URL}SurveyAnswer/GetSurveyQuestionAnswers/${surveyId}/${questionnaieId}/${sectionId}`,
  ).catch((err) => {
    if (!(err instanceof DOMException)) {
      //throw Error(err);
      throw err;
    }
  });
};

export const updateSurveyQuestionResponse = async (payload: SurveyQuestionPayloadModel) => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  return post(`${BASE_URL}SurveyAnswer/UpdateSurveyQuestionResponse`, payload).catch((err) => {
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
}

export const updateSurveyMeetingSection = async (payload: SurveySectionResponseModel) => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  return post(`${BASE_URL}SurveyAnswer/UpdateSurveySection`, payload).catch((err) => {
    if (!(err instanceof DOMException)) {
      throw Error(JSON.stringify(err));
    }
  });
}

export const updateSurveyStatus = async (surveyId: number, newStatusNum: number) => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  return post(`${BASE_URL}SurveyRequest/UpdateSurveyRequestStatus?surveyRequestId=${surveyId}&Status=${newStatusNum}`, {}).catch((err) => {
    if (!(err instanceof DOMException)) {
      throw Error(JSON.stringify(err));
    }
  });
}

export const updateSurveyDetails = async (payload: SurveyDetailsUpdateModel) => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  return post(`${BASE_URL}SurveyRequest/UpdateSurveyRequest`, payload).catch((err) => {
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
}

export const getSurveyTemplates = async () => {
  // Abort all previous requests..
  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();
  return get(`${BASE_URL}Survey/GetSurveyTemplates`).catch((err) => {
    // Do not throw errors when request has been aborted
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};

export const getSurveyInstructions = async () => {
  // Abort all previous requests..
  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();
  return get(`${BASE_URL}Survey/GetNotificationTemplates/1`).catch((err) => {
    // Do not throw errors when request has been aborted
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};

export const createUpdateInspectionType = async (data: any, surveyTypeId: number) => {
  const url =
    surveyTypeId === 0
      ? `${BASE_URL}SurveyRequest/CreateUpdateSurveyType`
      : `${BASE_URL}SurveyRequest/CreateUpdateSurveyType?surveyTypeId=${surveyTypeId}`;
  // Abort all previous requests..
  if (abortController) {
    abortController.abort();
  }
  //debugger
  const postDataBody = {
    'surveyType': { ...data, 'surveyTypeId': surveyTypeId, 'isActive': true },
  };

  abortController = new AbortController();
  return post(url, postDataBody).catch((err) => {
    // Do not throw errors when request has been aborted
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};

export const updateDefaultSurveyType = async (surveyTypeId: number) => {
  const url = `${BASE_URL}SurveyRequest/SetDefaultSurveyType/${surveyTypeId}`;
  // Abort all previous requests..
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  return post(url, surveyTypeId).catch((err) => {
    // Do not throw errors when request has been aborted
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};

export const getDefaultSurveyType = async () => {
  // Abort all previous requests..
  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();
  return get(`${BASE_URL}SurveyRequest/GetDefaultSurveyType`).catch((err) => {
    // Do not throw errors when request has been aborted
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
};
