import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType
}  from 'react-router-dom';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import {LogLevel} from '@grafana/faro-web-sdk';
import { RumInit } from '@maersk-global/telemetry-web-sdk';
import { ReactIntegration, ReactRouterVersion } from '@grafana/faro-react';
import env from 'react-dotenv';

let { REACT_APP_ENV: AppEnv, REACT_APP_API_KEY:ApiKey, REACT_APP_NAME:AppName } = env;

export const msalInstance = new PublicClientApplication(msalConfig);
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

window.addEventListener("DOMContentLoaded", function (e) {
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <BrowserRouter>
      <App msalInstance={msalInstance} />
    </BrowserRouter>
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const faro = RumInit({
  app: {
    name:AppName as string,
    version: "1.0.0",
    environment: AppEnv as string,
  },
  apiKey:ApiKey as string, 
   instrumentations:{
    interactions: true,   
    xhr: true,
    console:{
      disabledLevels:[LogLevel.TRACE,LogLevel.INFO]
    },
  }, 
  performanceInstrument:{
    ignoreEvents:['msal.*'] // To ignore certain events pass a list of exact events names or regex
  },
  isolate: true, 
  spanLimitInTrace: 3,
  useSendBeacon: true,
  transportBufferSize: 5,
  rateLimitBackoffMs: 5000,
  propagateTraceHeaderCorsUrls: [new RegExp('.*api.*')],
  debug: false, // false: Prevent showing log,error,etc captured in browser console
  traceLog: false, // Sends Traces to Loki ( For easy querying of traces)

   batching:{
    // enable/disable batch sending of events 
    enabled: true, // default
    // The interval in which to send event batches   
    sendTimeout:1000, // default
    // The size of the signal buffer before the batch is sent (overrides interval) 
    itemLimit: 4    
  }
});

faro.instrumentations.add(
  new ReactIntegration({
  // If the version of React Router is V6
  router: {
    version: ReactRouterVersion.V6,
    dependencies: {
      createRoutesFromChildren,
      matchRoutes,
      Routes,
      useLocation,
      useNavigationType
    }
  } 
})
);
